<template>
  <div class="w-100 clearfix">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Shop",
  data() {
    return {
      value: ''
    }
  }
}
</script>